//import React, { useRef } from "react";
import "./Join.css";
//import emailjs from "@emailjs/browser";
const Join = () => {
  /*const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_s4inx27",
        "template_w3euwfx",
        form.current,
        "VTvgg2wf2vsgANchG"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
*/

 //   function sendEmail(e){
   //     e.preventDefault();
            

          //  emailjs.sendForm('service_s4inx27','template_w3euwfx',e.target,'VTvgg2wf2vsgANchG')
     //       .then(res=>{
       //         console.log(res);
         //   }).catch(err=> console.log(err));
        
        //}
  return (
    <div className="join" id="Join-us">
      <div className="join-l">
        <hr />
        <div>
          <span className="stroke-text">ready to</span>
          <span> level up</span>
        </div>
        <div>
          <span>your body</span>
          <span className="stroke-text"> with us</span>
        </div>
      </div>

      <div className="join-r">
        <form className="email-container">
          <input
            type="email"
            name="user-email"
            placeholder="Enter your Email address"
          />
          <button className="btn btn-join">Join me</button>
        </form>
      </div>
    </div>
  );
};
export default Join;
